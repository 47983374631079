.integrations-container {
    width: 100%;
    height: 75vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem; 
    padding: 1rem; 
}
  
.integration-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1rem;
}

.integration-card:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 4px grey;
}

.integration-image {
    width: 60%;
    height: auto;
    max-height: 50%;
    object-fit: contain;
    margin: auto;
}

.integration-card.empty {
    position: relative;
    overflow: hidden;
}

.blurred-box {
    width: 100%;
    height: 100%;
    filter: blur(5px);
    border: 1px solid black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.connectwise-header {

}

.connectwise-header:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 768px) {
    .integrations-container {
        grid-template-columns: 1fr;
    }
}